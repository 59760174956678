.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.programs-header{
    display: flex;
    gap: 9rem;
    color: white;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
    justify-content: center;
    font-style: italic;
}
.program-categories{
    display: flex;
    gap: 1rem;

}
.category{
    display: flex;
    flex-direction: column;
    background-color: var(--gray);
    color: white;
    justify-content: space-between;
    padding: 2rem;
    gap: 1rem;
}
.category>:nth-child(1){
  width: 2rem;
  height: 2rem;
  fill: white;
}
.category>:nth-child(2){
    font-weight: bold;
    font-size: 1rem;
  }
  .category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
  }
  .join-now{
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  .join-now>img{
    width: 1rem;
  }
  .category:hover{
    cursor: pointer;
    background: var(--planCard);
  }